.app{
    height: 100vh;

    .sections{
        width: 100%;
        height: calc(100vh - 40px);
        position: relative;
        top: 40px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        scroll-snap-align: start;
        &::-webkit-scrollbar{
            display: none;
        }
        > *{
            // width: 100vh;
            // height: calc(100vh - 40px);
            scroll-snap-align: start;
        }
    }
}


// .app{
//     height: 100vh;
//     .sections{
//         width: 100%;
//         height: 100%;
//         position: relative;
//     }
// }

// true cyan optical illusion #0074A0 #FF1901 white