@import "../../global.scss";

.navbar{
    width: 100%;
    height: 40px;
    background-color: black;
    color: white;
    position: fixed;
    z-index: 3;
    transition: all 1s ease;

    .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        

        .left{
            display: flex;
            align-items: center;
            
            .logo{

                font-size: 24px;
                font-weight: 700;
                padding-left: 10px;
                text-decoration: none;
                color: inherit;
                @include mobile{
                    font-size: 12px;
                    font-weight: 200;
                }
            }

            .navlink{
                padding-left: 7px;
                padding-right: 7px;
                font-size: 24px;
                font-weight: 400;
                text-decoration: none;
                color: inherit;
                @include mobile{
                    font-size: 12px;
                    font-weight: 200;
                }
            }
        }
        .right{
            padding: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
        }
    }
}